import Service from '../Service'
import { LandingV4Instance } from './Instance'
// import { LandingV3Instance } from './InstanceV3'


/**
 * @class
 */
export class verifyBank extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
    this._disbursementMethod = ''
    this._cardNumber = ''
    this._clabe = ''    
  }

  get data () {
    return {
      creditApplicationId: `${this._creditApplicationId}`,
      disbursementMethod: `${this._disbursementMethod}`,
      cardNumber: `${this._cardNumber}`,
      clabe: `${this._clabe}`,      
    }
  }

  get dataroadmaprestart(){
    return{
        creditApplicationId: `${this._creditApplicationId}`,
        taskId: "VERICUENTA",
        notes: ""
      }
  }
  
  /**
   * @param  {String} disbursementMethod
   */
  setDisbursementMethod(disbursementMethod) {
    this._disbursementMethod = `${disbursementMethod}`
  }
  
  /**
   * @param  {String} cardNumber
   */
  setCardNumber(cardNumber) {
    this._cardNumber = `${cardNumber}`
  }

  
  /**
   * @param  {String} clabe
   */
  setClabe(clabe) {
    this._clabe= `${clabe}`
  }
  
  /**
   * @param  {Array} acceptedInsuranceIds
   */
  setAcceptedInsuranceIds(acceptedInsuranceIds) {
    this._acceptedInsuranceIds = acceptedInsuranceIds
  }
  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send () {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/corrections/disbursement',
      data: self.data
    }))
  }

  sendRoadmapRestart () {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/roadmap/restart',
      data: self.dataroadmaprestart
    }))
  }

  signatureRestart (id) {
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: `/roadmap/${id}`,
    }))
  }
}
